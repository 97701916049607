
GenericTagsDropDown = React.createClass({
    propTypes: {
        tagGroups: React.PropTypes.arrayOf(React.PropTypes.object),
        tagsSelected: React.PropTypes.arrayOf(React.PropTypes.string),
    },

    getInitialState() {
        const tagHexColors = {}
        const tagDisplayNameMap = {}
        for (const tagGroup of this.props.tagGroups) {
            tagHexColors[tagGroup.id] = tagGroup['color_hex']
            tagDisplayNameMap[tagGroup.id] = {}
            for (const tagName of tagGroup.tags) {
              tagDisplayNameMap[tagGroup.id][tagName] = tagGroup.tag_name_displays[tagName] || tagName
            }
        }
        return {
            tagGroups: this.props.tagGroups,
            tagHexColors: tagHexColors,
            tagDisplayNameMap: tagDisplayNameMap,
            filterText: '',
            isOpen: false,
        }
    },

    tagClickHandler(tagHash) {
        if (this.props.tagsSelected.indexOf(tagHash) === -1) {
            this.setState({
                tagsSelected: this.props.tagsSelected.push(tagHash),
                filterText: '',
                isOpen: false,
            })
        } else {
            this.setState({
                filterText: '',
                isOpen: false,
            })
        }
    },

    removeTagClickHandler(tagHash) {
        const index = this.props.tagsSelected.indexOf(tagHash)
        if(index !== -1) {
            this.setState({ tagsSelected: this.props.tagsSelected.splice(index, 1) })
        }
    },

    onFilterTagChange(event) {
        this.setState({ filterText: event.target.value })
    },

    onInputFocus() {
        this.setState({ isOpen: true })
    },

    render() {
        return (
            <div>
                <span
                  style={{
                    display: 'inline-block',
                    fontWeight: '600',
                    width: '100%',
                    margin: '40px 0 10px',
                    color: 'rgba(83, 83, 83, 1.00)',
                  }}
                >
                  Add reservation tags to reservations booked on this access rule
                </span>
                <div
                    style={{
                        minHeight: '25px',
                        border: '1px solid rgba(218, 218, 218, 1.00)',
                        borderRadius: '3px',
                        padding: '5px 10px 10px 10px',
                    }}
                >
                    <div
                        style={{
                            minHeight: '25px',
                            borderRadius: '3px',
                        }}
                    >
                        <SelectedTags
                            removeTagClickHandler={this.removeTagClickHandler}
                            tagsSelected={this.props.tagsSelected}
                            tagHexColors={this.state.tagHexColors}
                            tagDisplayNameMap={this.state.tagDisplayNameMap}
                        />
                        <FilterTagsInput
                            onFocus={this.onInputFocus}
                            onChange={this.onFilterTagChange}
                            filterText={this.state.filterText}
                        />
                    </div>
                </div>
                {this.state.isOpen &&
                    <TagsDropDownMenu
                        tagClickHandler={this.tagClickHandler}
                        tagGroups={this.state.tagGroups}
                        tagHexColors={this.state.tagHexColors}
                        tagDisplayNameMap={this.state.tagDisplayNameMap}
                        filterText={this.state.filterText}
                    />
                }
            </div>
        )
    },
})

const FilterTagsInput = (props) => {
    return (
        <input
            onFocus={props.onFocus}
            onChange={props.onChange.bind(this)}
            value={props.filterText}
            style={{
                width: '100%',
                display: 'block',
                marginTop: '5px',
                outline: 'none',
                border: 'none',
                padding: '6px 0px',
            }}
        />
    )
}

const SelectedTags = (props) => {
    const selectedTagsList = []
    for (const tagHash of props.tagsSelected) {
        const tagProps = tagHash.split('##')
        const tagGroupId = tagProps[1]
        const tagName = tagProps[3]
        if (!tagGroupId || !tagName) {
            continue
         }
        const tagHexColor = props.tagHexColors[tagGroupId]
        const tagDisplayName = tagGroupId in props.tagDisplayNameMap ? props.tagDisplayNameMap[tagGroupId][tagName] : null
        if (!tagHexColor || !tagDisplayName) {
            continue
         }
        selectedTagsList.push(
            <TagFlag
                onClick={props.removeTagClickHandler}
                tagHash={tagHash}
                tagHexColor={tagHexColor}
                tagDisplayName={tagDisplayName}
            />
        )
    }
    return (
        <div
            style={{
                display: 'block',
            }}
        >
            {selectedTagsList}
        </div>
    )
}

const TagFlag = (props) => {
    return (
        <span
            style={{
                cursor: 'default',
                paddingLeft: '10px',
                lineHeight: '25px',
                backgroundColor: props.tagHexColor,
                display: 'inline-block',
                marginRight: '10px',
                marginTop: '5px',
                borderRadius: '3px',
            }}
        >
            <input
                type="hidden"
                name="reservation_tags"
                value={props.tagHash}
            />
            { props.tagDisplayName }
            <p onClick={props.onClick.bind(this, props.tagHash)}
                style={{
                    cursor: 'pointer',
                    float: 'right',
                    paddingRight: '7px',
                    marginLeft: '20px',
                }}>&#x2716;</p>
        </span>
    )
}

const TagsDropDownMenu = (props) => {
    const tagsList = []
    for (const tagGroup of props.tagGroups) {
        let tagNames = []
        if (props.filterText) {
            for (const tagName of tagGroup.tags) {
                const tagNameDisplay = props.tagDisplayNameMap[tagGroup.id][tagName]
                if (tagName.toLowerCase().includes(props.filterText) || tagNameDisplay.toLowerCase().includes(props.filterText)) {
                    tagNames.push(tagName)
                }
            }
            if (tagNames.length == 0) { continue }
        } else {
            tagNames = tagGroup.tags
        }
        tagsList.push(
            <TagsList
                tagClickHandler={props.tagClickHandler}
                tagObj={tagGroup}
                tagNames={tagNames}
                tagDisplayNameMap={props.tagDisplayNameMap}
                filterText={props.filterText}
            />
        )
    }
    return (
        <div
            style={{
                height: '150px',
                border: '1px solid rgba(218, 218, 218, 1.00)',
                overflow: 'auto',
                padding: '10px',
            }}
        >
            {tagsList}
        </div>
    )
}

const getTagHash = (tagObj, tagName) => {
    return [
        tagObj['privacy'],
        tagObj['id'],
        tagObj['name'],
        tagName,
    ].join('##')
}

const TagsList = (props) => {
    const tagItemList = []
    for (const tagName of props.tagNames) {
        tagHash = getTagHash(props.tagObj, tagName)
        tagItemList.push(
            <TagItem
                onClick={props.tagClickHandler}
                tagHash={tagHash}
                tagNameDisplay={props.tagDisplayNameMap[props.tagObj.id][tagName]}
                tagHexColor={props.tagObj['color_hex']}
            />
        )
    }
    return(
        <div
            style={{
                cursor: 'default',
                color: '#999',
                fontWeight: '700',
                paddingBottom: '10px',
            }}
        >
            {props.tagObj.name}
            {tagItemList}
        </div>
    )
}

const TagItem = (props) => {
    return(
        <div
            onClick={props.onClick.bind(this, props.tagHash)}
            value={props.tagHash}
            style={{
                cursor: 'pointer',
                paddingLeft: '10px',
                lineHeight: '25px',
            }}
        >
            <TagBubble tagHexColor={props.tagHexColor} />
            <div
                style={{
                    display: 'inline-block',
                }}
            >
                {props.tagNameDisplay}
            </div>
        </div>
    )
}

const TagBubble = (props) => {
    return(
        <div
            style={{
                height: '10px',
                width: '10px',
                backgroundColor: props.tagHexColor,
                borderRadius: '50%',
                marginRight: '5px',
                display: 'inline-block',
            }}
        />
    )
}
